import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RegisterService } from "../services/register/register.service";
import { CookieService } from 'ngx-cookie-service';
import { HttpClient,HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { UserService } from '../services/user/user.service';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  currentbtn = true;
  hide: any = true;
  
  password;
  password1;
  show = false;
  show1 = false;
  hide1: any = true;
  passText: any = 'password';
  confirmPassText: any = 'password';
  forgetpasswordmobile: FormGroup;
  forget: any;
  frorget:any=true;
  sucessmessage:any=false;
  UniqueNumber:any;
  submitted = false;
  arr1:any=[]
  uniqueKey:any;
  constructor(
    private route11: Router,
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private cookieService: CookieService,
    private http:HttpClient,
    private userService: UserService, 
    private route: ActivatedRoute,
  ) { 
    
  }

  ngOnInit(): void {
    this.password = 'password';
    this.password1 = 'password';

    let url:any=this.route11.url;
    console.log(url);
   
    this.route.snapshot.url.forEach(segment => {    
      // Logs each segment of the URL 
     
         this.arr1.push(segment.path)
 
      });
      this.uniqueKey=this.arr1[2];
      console.log('uniqueKey',this.uniqueKey)
    this.forgetpasswordmobile = this.formBuilder.group({
      Newpassword: ['', [Validators.required, Validators.minLength(6)]],
      Confrompassword:['',Validators.required,],
      UniqueNumber:this.uniqueKey
    },
    {
      validator:this.MustMatch("Newpassword", "Confrompassword"),
    }
   
  );
   
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
  
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
  
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
        this.currentbtn = true;
      } else {
        matchingControl.setErrors(null);
        this.currentbtn = false;
      }
    };
  }
  passwordMatchValidator(form: FormGroup) {
    const password = form.get('password')?.value;
    const confirmpassword = form.get('confirmpassword')?.value;
    
    return password === confirmpassword ? null : { mismatch: true };
    alert( 'Password does not match')
  }
  get f() {
    return this.forgetpasswordmobile.controls;
  }
  forgetnewpassword(){
    this.submitted = true;

    if (this.forgetpasswordmobile.invalid) {
      return;
    }
    let params = new HttpParams();
    params = params.append('password', this.forgetpasswordmobile.value.password);
    params = params.append('confirmpassword', this.forgetpasswordmobile.value.confirmpassword);
    params = params.append('UniqueNumber', this.forgetpasswordmobile.value.UniqueNumber);

    debugger
      //    this.registerService
      //   .forgetpassword(params)
      //  .subscribe((res) => {
      //    console.log(res);
      //   });
      this.http.get(environment.serviceUrl+'account/VerifyChangepasswordLink?'+params).subscribe((res:any)=>{
  

        if (res.success == true) {
          this.frorget=false;
        this.forget = res.data;
        // this.userService.showNotification('success', res.message);
        // alert(res.message);

        this.currentbtn = true;
        this.forgetpasswordmobile.reset();
 
        this.sucessmessage=true;
        }
        else{
          alert(res.message);

          // this.userService.showNotification('danger', res.message);
        }
      
      })
 }

 onClick() {
  debugger
  if (this.password === 'password') {
    this.password = 'text';
    this.show = true;
  } else {
    this.password = 'password';
    this.show = false;
  }
}
onClick1() {

  if (this.password1 === 'password') {
    this.password1 = 'text';
    this.show1 = true;
  } else {
    this.password1 = 'password';
    this.show1 = false;
  }
}

  onchangebtn(e) {
    debugger
    this.submitted=true
    if (this.forgetpasswordmobile.invalid) {
      return;
    }
    console.log(e.value.email);
    
    this.currentbtn = false;
  }


  onchangebtn1(e) {
    debugger
    this.submitted=true
    if (this.forgetpasswordmobile.invalid) {
      return;
    }
    console.log(e.value.email);
    
    this.currentbtn = false;
  }
  
}
